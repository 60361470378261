import React from "react"
import Mixer from "./mixer.png"

export default function WorkCollection(props) {
  return (
    <div className={"content"}>
      <WorkItem
        photo={false}
        link="https://www.youtube.com/embed/4QiGYtd3qNI"
        title={"Skype for HoloLens"}
        text={
          "Skype for HoloLens is the first immersive communications application in the mixed reality space. With holograms in Skype, friends and colleagues can see your environment as you see it and draw holograms in your space."
        }
        // itemClick={props.itemClick}
      />
      <WorkItem
        photo={false}
        link="https://www.youtube.com/embed/kh0be6z-Zl8?start=66"
        title={"Remix3D"}
        text={
          "Remix3D is a content platform fueling 3D experiences for creators and consumers. It’s aim is to enable mixed reality and 3D experiences for users of Windows 10, so everyone can experience, create, and share amazing ideas in a new dimension."
        }
        // itemClick={props.itemClick}
      />

      <WorkItem
        photo={true}
        link={Mixer}
        title={"Mixer"}
        text={
          "Mixer brings streamers and viewers closer together by blurring the line between watching and playing, empowering 20 million MAUs to redefine live streaming."
        }
        // itemClick={props.itemClick}
      />
    </div>
  )
}

function WorkItem(props) {
  return (
    <>
      <div className={"workItem"}>
        <WorkItemMedia link={props.link} photo={props.photo} />
        <WorkItemText
          title={props.title}
          text={props.text}
          // itemClick={props.itemClick}
        />
      </div>
      <hr />
    </>
  )
}

function WorkItemText(props) {
  return (
    <div className={"itemTextContainer"}>
      <div
        className={"itemTitle"}
        // onClick={() => props.itemClick(props.title)}
      >
        {props.title}
      </div>
      <div className={"itemText"}>{props.text}</div>
    </div>
  )
}

function WorkItemMedia(props) {
  return (
    <div className={"itemMediaContainer"}>
      {!props.photo && (
        <iframe
          width="300"
          height="169"
          src={props.link}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
      {props.photo && (
        <img className={"itemImg"} src={props.link} alt="picture" />
      )}
    </div>
  )
}
