import React from "react"

export default function Contact() {
  return (
    <div className={"content"}>
      Want to chat or collaborate? <br /> Feel free to drop me a message at{" "}
      <a href={"mailto: matchamochiwaffles@gmail.com"}>
        matchamochiwaffles@gmail.com
      </a>
      .
    </div>
  )
}
