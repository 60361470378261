import React from "react"

export default function About() {
  return (
    <div className={"content"}>
      Hi there! I'm Szeyin. I am a designer + engineer hybird. 
      <br />
      <br />
      I love creating interactive experiences. I am drawn to the intersection of
      technology, design, and humans. I find joy in the collaborative process of
      making the unknown known. <br />
      <br />I am currently a product design prototyper{" "}
      <a href="https://www.facebook.com/facebookrealitylabs" target="_blank">
        @Facebook Reality Labs{" "}
      </a>{" "}
      on the Input Explorations team. Previously, I was a
      software engineer{" "}
      <a
        href="https://www.microsoft.com/en-us/"
        target="_blank"
        rel="noreferrer"
      >
        @Microsoft
      </a>{" "}
      <a
        href="https://www.microsoft.com/en-us/hololens"
        target="_blank"
        rel="noreferrer"
      >
        @HoloLens
      </a>{" "}
      and{" "}
      <a href="https://www.xbox.com/en-US" target="_blank" rel="noreferrer">
        @Xbox
      </a> for 5 years. Former intern{" "}
      <a href="https://www.ea.com/" target="_blank" rel="noreferrer">
        @EA
      </a>{" "}
      and{" "}
      <a href="https://www.etsy.com/" target="_blank" rel="noreferrer">
        @Etsy
      </a>
      .
      <br />
      <br />
      Outside of work, I spend my time on social impact. As an alumni trustee{" "}
      <a
        href="https://www.scrippscollege.edu/"
        target="_blank"
        rel="noreferrer"
      >
        @Scripps College
      </a>
      , I help imagine the future of women liberal arts education.
      <br />
      <br />
      Things that spark joy for me: art exhibitions, photography, indie games,
      crafts, hiking, traveling, and a cup of hot green tea.
    </div>
  )
}
