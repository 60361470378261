import React from "react"
import WorkCollection from "./workcollection"

export default function Work(props) {
  //   const handleClick = title => {
  //     console.log(title)
  //   }

  return <WorkCollection />
}
