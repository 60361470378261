import React, { useState } from "react"
import instagram from "./instagram.png"
import linkedin from "./linkedin.png"
import About from "./about"
import Contact from "./contact"
import Work from "./work"

export default function Nav() {
  const [activeTab, setActiveTab] = useState("about")
  return (
    <>
      <nav>
        <ul>
          <li
            className={activeTab === "about" ? "selected" : ""}
            onClick={() => setActiveTab("about")}
          >
            About
          </li>
          <li
            className={activeTab === "create" ? "selected" : ""}
            onClick={() => setActiveTab("create")}
          >
            Work
          </li>
          <li
            className={activeTab === "contact" ? "selected" : ""}
            onClick={() => setActiveTab("contact")}
          >
            Contact
          </li>
          <a href="https://www.linkedin.com/in/leeszeyin/" target="_blank">
            <img className={"logo"} src={linkedin} alt="Linkedin Logo" />
          </a>
          <a href="https://www.instagram.com/szeyin.lee" target="_blank">
            <img className={"logo"} src={instagram} alt="Instagram Logo" />
          </a>
        </ul>
      </nav>
      <div className={"contentContainer"}>
        {activeTab === "about" && <About />}
        {activeTab === "create" && <Work mode={0} />}
        {activeTab === "contact" && <Contact />}
      </div>
    </>
  )
}
