import React from "react"
import Nav from "../components/nav"

export default function Home() {
  return (
    <>
      <h1>Szeyin Lee</h1>
      <Nav />
    </>
  )
}
